/* global URL */

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:MultiWalletCardFileUploadCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('MultiWalletCardFileUploadCtrl', MultiWalletCardFileUploadCtrl);

  function MultiWalletCardFileUploadCtrl(appConfig,
                          orderType,
                          FormControl,
                          $q,
                          _,
                          $scope,
                          OrderService,
                          $mdDialog,
                          $document,
                          $translate,
                          ItemList,
                          Auth,
                          Auxiliary) {
    var vm = this
      , formControl = new FormControl()
      , deferred
      , availableFile = {}
      , downloadableBlob = ''
      , fileRelatedError
      , EdenredECSselectedProductType = 'EdenredEcsSelectedProductType'
      , selectedProductType = localStorage.getItem(EdenredECSselectedProductType)
      , newCardSample = '/files/rewin-minta_kartya_rendeles_' + selectedProductType + '.xlsx'
      , topUpSample = '/files/rewin-minta_kartya_feltoltes_' + selectedProductType + '.xlsx'
      , multiWalletItems = 'multiWalletItems'
      , itemList = new ItemList();

    $scope.$on('$stateChangeSuccess', onStateChangeSuccess);

    function onStateChangeSuccess() {
      $mdDialog.show({
        controller: 'DataReconciliationProcessDialogCtrl',
        controllerAs: 'dataReconciliationProcessDialog',
        templateUrl: 'wb-order/views/data-reconciliation-process-dialog.tpl.html',
        bindToController: true,
        parent: angular.element($document.body),
        clickOutsideToClose: true,
        focusOnOpen: false
      });
    }

    vm.sampleFile = (orderType === '1') ? newCardSample : topUpSample;

    vm.fileRelatedError = false;

    vm.invalidateFile = function invalidateFile(fileName) {
      availableFile.name = fileName || '';
      availableFile.valid = false;
    };

    vm.invalidateFile();

    vm.sendToAPI = function sendToAPI($files, $event, $flow) {
      deferred = $q.defer();
      formControl.process(deferred.promise)
        .catch(Auxiliary.getHeartbeat());
      $flow.opts.withCredentials = true;
      $flow.opts.headers = {token: Auth.getToken()};
      $flow.opts.target = appConfig.api.url + 'data-reconciliations/files';
      $flow.upload();
    };

    vm.handleAPIErrors = function handleAPIErrors($files, $message, $flow) {
      /*jshint unused:false */
      /*eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = {};

      vm.invalidateFile($files.name);
      response.status = 400;
      response.data = _.defaultsDeep({}, angular.fromJson($message));
      handleErrorDialog(response.data.error);
      deferred.reject(response);
    };

    vm.handleAPISuccess = function handleAPISuccess($files, $message, $flow) {
      /*jshint unused:false */
      /*eslint "no-unused-vars": [2, {"args": "none"}]*/
      var response = _.defaultsDeep({}, angular.fromJson($message));

      availableFile.name = response.flowFilename;
      availableFile.valid = true;
      fileRelatedError = false;
      itemList.setItems(response.items);
      itemList.setItemListStorage(multiWalletItems);
      deferred.resolve();
    };

    vm.deleteFile = function deleteFile() {
      $mdDialog
        .show(
          $mdDialog
            .confirm({focusOnOpen: false})
            .parent(angular.element($document.body))
            .clickOutsideToClose(true)
            .content($translate.instant('order.bulk.files.delete.dialog', {fileName: vm.getAvailableFileName()}))
            .ok($translate.instant('order.itemDialog.delete'))
            .cancel($translate.instant('order.itemDialog.cancel'))
        )
        .then(function () {
          vm.invalidateFile();
          OrderService.clearStorageItems();
        });
    };

    vm.hasFile = function hasFile() {
      return !_.isEmpty(availableFile.name);
    };

    vm.isValidFile = function isValidFile() {
      return availableFile.valid;
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.order = {
      status: 'PRG',
      type: orderType
    };

    vm.getAvailableFileName = function getAvailableFileName() {
      return availableFile.name;
    };

    vm.getUploadedFileUrl = function getUploadedFileUrl() {
      return downloadableBlob;
    };

    vm.openSampleAlert = function openSampleAlert() {
      $mdDialog
        .show(
          $mdDialog
            .alert({focusOnOpen: false})
            .parent(angular.element($document.body))
            .clickOutsideToClose(true)
            .content($translate.instant('order.sampleFileDownloadAlert'))
            .ok($translate.instant('order.itemDialog.close'))
        );
    };

    vm.fileRelatedError = function () {
      return fileRelatedError;
    };

    function handleErrorDialog(errors) {
      fileRelatedError = errors && !_.isUndefined(errors.file) ? Object.keys(errors.file) : false;

      if (!fileRelatedError) {
        $mdDialog.show({
          controller: 'FileBulkErrorDialogCtrl',
          controllerAs: 'fileBulkErrorDialog',
          templateUrl: 'wb-order/views/fileBulkErrorDialog.tpl.html',
          parent: angular.element($document.body),
          bindToController: true,
          locals: {
            errors: errors
          },
          focusOnOpen: false
        });
      }
    }
  }
}());
